<template>
  <div class="q-pa-md">
    <div style="margin-top: 50px; margin-bottom: 40px">
        <div class="q-gutter-md row justify-center">
            <q-img src="../assets/logo.png" style="width: 300px"></q-img>
        </div>
        <div class="q-gutter-md row justify-center">
            <div style="font-size:20 px;">{{ $t("app.brand_title") }}</div>
        </div>
    </div>


    <q-form @submit="onSubmit" class="q-gutter-md">
        <div v-if="relogin" class="col row justify-center">
            <p></p>
        </div>
        <div v-if="relogin" class="col row justify-center">
            <p style="color: red">{{ $t("login.relogin") }}</p>
        </div>
        <div class="col row justify-center">
            <div style="width: 300px">
                <q-input v-model="username" stack-label :label="$t('login.username') + '(*)'"/>
            </div>
        </div>
        <div class="col row justify-center">
            <div style="width: 300px">
                <q-input v-model="password" stack-label :label="$t('login.password') + '*'" type="password" />
            </div>
        </div>
        <div style="height: 10px"></div>
        <div class="col row justify-center">
            <q-btn outline :label="$t('login.submit')" type="submit" color="accent"/>
        </div>
        <div style="height: 20px"></div>
        <div class="column ">
            <p class="row justify-center">{{ $t("login.forget_password") }}</p>
            <p class="row justify-center">{{ $t("login.contact_us") }}</p>
        </div>
    </q-form>
  </div>
</template>

<script>
export default {
    data () {
        return {
            email: '',
            username: '',
            password: '',
            relogin: false,
        }
    },
    methods: {
        async onSubmit() {
            let formData = new FormData();
            
            formData.append('username', this.username);
            formData.append('password', this.password);

            let response = await fetch("/api/v1/authtoken", {
                body: formData,
                method: "post"
            });            
            if (response.status == 200) {
                let data = await response.json();
                window.sessionStorage.setItem('token', data.token);
                window.sessionStorage.setItem('user_id', data.id);
                if (data.is_staff) {
                    window.sessionStorage.setItem('is_staff', data.is_staff);
                } else {
                    window.sessionStorage.removeItem('is_staff');
                }
                this.relogin = false;
                this.$router.push({ name: 'Home'})
            } else {
                this.relogin = true;
            }
        }
    }
}
</script>
